import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { MainSettingsModel } from '../../website-settings/models/main-settings-model';
import { SimpleResponse } from '@shared/interfaces/iresponse';
import { IDashboardSetting, IDashboardSettingBase } from '@shared/interfaces/Idashboard-setting';
export interface fetchPayload {
  OrganisationId: String,
  OrganizationType: String
}

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {

  Refresh = new Subject<fetchPayload>();
  RefreshWebsiteSetting = new Subject<any>();
  MainSettings: BehaviorSubject<MainSettingsModel|null> = new BehaviorSubject<MainSettingsModel|null>(null)
  SwitchUser = new Subject<boolean>()
  UpdateUser: Subject<boolean> = new Subject<boolean>();
  orgUpdateTrigger:Subject<boolean> = new Subject();
  private OrganizationSettings: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }

  RefreshSideBar(arg: any) {
    if (arg) {
      this.Refresh.next(arg);
      return null
    } else {
      return this.Refresh.asObservable()
    }
  }
  getUpdateUser(args?:any) {
    if (args) {
      this.UpdateUser.next(true)
      return null
    }
    return this.UpdateUser.asObservable()
  }
  public getSetMainSettings(args?:any) {
    if (args) {
      this.MainSettings.next(args);
      return null
    }
    return this.MainSettings.asObservable()
  }
  RefreshWebsiteSettings(arg: any) {
    if (arg) {
      this.RefreshWebsiteSetting.next(arg)
      return null
    } else {
      return this.RefreshWebsiteSetting.asObservable()
    }
  }
  SwitchUseAdminrHandler(setter: boolean, data?:any) {
    this.SwitchUser.next(setter);
    if (data) {
      this.RefreshSideBar(data)
    }
  }
  GetSwitchRole() {
    return this.SwitchUser.asObservable()
  }
  SetOrganizationSettings(Setting: any) {
    this.OrganizationSettings.next(Setting)
  }
  FetchOrganizationSettings(): Observable<any> {
    return this.OrganizationSettings.asObservable()
  }
  FetchOrganzationTypes() {
    return <any>this.http.get(`${environment.ctUrl}Register/FetchOrganizationsTypes`)
  }
  FetchCountries() {
    let payload = {
      "PageNo": 0,
    }
    return this.http.get(`${environment.ctUrl}Country/FetchCountries`)
  }
  FetchSideNav(type: string) {
    return this.http.get(`${environment.ctUrl}Settings/FetchOrganizationSettings/${type}`)
  }
  FetchFavouriteOrgEmailIds(organization: string) {
    return this.http.get(`${environment.ctUrl}Favorite/FetchFavoriteOrgEmailIDs/${organization}`)
  }
  FetchPaymentMethods(organization: string, PageNo = 1) {
    let model = {
      OrganizationID: organization,
      PageNo
    }
    return this.http.post(`${environment.ctUrl}PaymentGateway/FetchPaymentMethods`, model)
  }
  SavePaymentGatewayDetail(PaymentGateway: any) {
    return this.http.post(`${environment.ctUrl}PaymentGateway/SavePaymentGateway`, PaymentGateway)
  }
  FetchAllUserDefinedFields() {
    return this.http.get(`${environment?.ctUrl}UserdefinedField/FetchUserdefinedFeilds`)
  }
  ConfirmStripeDonation(paymentInfo: any) {
    return this.http.post(`${environment.ctUrl}Donation/ConfirmStripeDonation`, paymentInfo)
  }
  SaveDonationConfirmation(model: any) {
    return this.http.post(`${environment.ctUrl}Donation/SaveDonationConfirmationDetails`, model)
  }
  FetchDashboardSetting(Id:number) {
    return <Observable<SimpleResponse<IDashboardSettingBase<string>>>>this.http.get(`${environment.ctUrl}Dashboard/FetchDashboardSetting/${Id}`)
  }
  FetchAdvancedSearchFilters() {
    return this.http.post(`${environment.ctUrl}Menu/FetchAdvancedSearchFilters`,{})
  }
}
